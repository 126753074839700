<template>
    <div class="board-page">
        <div class="data-preview">
            <div class="preview-item">
                <div class="icon-box">
                    <elem-icon name="visits"></elem-icon>
                </div>
                <p class="name">待取货订单</p>
                <div class="value-box">
                    <p class="value">1000</p>
                    <p class="compare">单</p>
                </div>
            </div>
            <div class="preview-item">
                <div class="icon-box">
                    <elem-icon name="add_user"></elem-icon>
                </div>
                <p class="name">待支付订单</p>
                <div class="value-box">
                    <p class="value">240</p>
                    <p class="compare">单</p>
                </div>
            </div>
            <div class="preview-item preview-yellow">
                <div class="icon-box">
                    <elem-icon name="order"></elem-icon>
                </div>
                <p class="name">总支付订单</p>
                <div class="value-box">
                    <p class="value">15000000</p>
                    <p class="compare">元</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            info: {},
        }
    },
}
</script>

<style lang="less">
.board-page {
    .data-preview {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .preview-item {
            position: relative;
            width: ~"calc(100% / 4 - 20px)";
            height: 160px;
            padding: 15px 20px;
            margin: 5px 10px 5px 10px;
            background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
            overflow: hidden;
            border-radius: (10px);
            box-sizing: border-box;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            flex-direction: column;
            justify-content: space-evenly;

            &:nth-child(2) {
                background-image: linear-gradient(to top, #48c6ef 0%, #6f86d6 100%);
            }

            .icon-box {
                width: 40px;
                height: 40px;
            }

            .name {
                font-size: 12px;
                letter-spacing: 2px;
                color: #f1f1f1;
            }

            .value-box {
                display: flex;
                align-items: flex-end;

                .value {
                    color: #fff;
                    font-size: 30px;
                    line-height: 30px;
                    font-weight: 500;
                }

                .compare {
                    margin-left: 10px;
                    color: #fff;
                    font-size: 13px;
                    opacity: 0.6;
                }
            }

            @media (max-width: 1000px) {
                & {
                    width: ~"calc(100% / 2 - 20px / 2)";

                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                }
            }
        }

        .preview-red {
            background-image: linear-gradient(-225deg, #a445b2 0%, #d41872 52%, #ff0066 100%);
        }

        .preview-yellow {
            background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
        }
    }
}
</style>
